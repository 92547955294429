import { useEffect, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import { manifest } from './manifest';
import { Sensors } from "./ModelTypes";
import { useScroll, motion, useSpring } from "framer-motion";

import { useAtom } from 'jotai'
import { atomWithStorage } from 'jotai/utils'

import { ReactSVG } from 'react-svg';
import { RWebShare } from "react-web-share";

import { IconTools } from '@tabler/icons-react';
import { IconHandClick } from '@tabler/icons-react';
import { IconEye } from '@tabler/icons-react';
import { IconEar } from '@tabler/icons-react';

//----------------------------------------------------------------------------
const SensorsView = (props:{
  sensors: Sensors,
}) => {
  return (
    <div className="flex items-center h-12 pr-4 ml-4 mr-4 text-2xl text-black justify-left">
      {props.sensors.move && <ReactSVG className="w-8 h-8 m-2 text-black stroke-current fill-none"
      src="https://solace-cdn.slv.tools/moveall_icon.svg" />}
      {props.sensors.touch && <IconHandClick className="m-2"/>}
      {props.sensors.audio && <IconEar className="m-2"/>}
      {props.sensors.visual && <IconEye className="m-2"/>}
      {props.sensors.tool && <IconTools className="m-2"/>}
    </div> 
  )
}

//----------------------------------------------------------------------------
const CellView = (props:{
  title: String,
  thumb: String,
  description: String,
  sensors: Sensors,
  onClick?: () => void
}) => {
  
  const linkto = "/"+props.title
  const seed= "url('https://solace-cdn.slv.tools/cdn-cgi/image/width=480/"+props.thumb+"')"
  
  const countAtom = useMemo( () => (
    atomWithStorage(String(props.title + "_count"), 0)
  ), [props.title] )
  const [count, setCount] = useAtom(countAtom)

  return (

    //h-16 space before first cell
       
    <li className="bg-opacity-0 bg-solacegray h-100 " role="menuitem" onClick={ () => {
      props.onClick && props.onClick()
    }} >

      <Link to={ linkto } onClick={ () => setCount( (e) => e + 1) } >
        
        <div className="h-16 " />
        
        <div className="pt-5 ml-3 mr-3 bg-solacegray rounded-xl">
          
          <div className="ml-5 mr-5 bg-center bg-no-repeat bg-cover rounded-md opacity-100 h-28 bg-solacegray"
              style={{ backgroundImage : seed }}>
          </div>
          
          <div className="h-14" />

          <div 
            className="pl-5 text-5xl align-middle bg-solacegray font-newsreader">
            { props.title }
          </div>
        
          <div className="pt-3 pl-5 pr-5 text-xl font-normal bg-solacegray font-satoshi ">
            { props.description }
          </div> 
  
          <div className="h-4" />


          <div className="grid grid-flow-col grid-cols-3 ">

           <div className="col-start-1 col-end-3 ">
              <SensorsView sensors={props.sensors}/>
            </div>
              
            <div className="col-start-3 ">
            {
            (count > 0) ? 
              <div className="grid grid-flow-col grid-cols-3 ">
                <div className="col-start-1 ">
                  <ReactSVG className="w-6 h-6 m-2 text-black fill-current justify-self-right "
                    src="https://solace-cdn.slv.tools/tick_view2.svg" />
                </div>

                <div className="col-start-2 mt-2 text-base text-solacegold">
                  Viewed
                </div>
                
              </div>
            :
              <></>
            }
            </div>

          </div>

          <div className="h-6"></div>

        </div>
      </Link>
    </li>
  )
}

//----------------------------------------------------------------------------
export default function List(props: {
  onClick?: (element: JSX.Element) => void
}) {

  document.title = "Solace Experiences"
  
  const headingRef = useRef<HTMLHeadingElement | null>(null)
  const experiences = useRef(Array.from(manifest.scenes))
  const { scrollYProgress } = useScroll()

  useEffect(() => {
    headingRef.current?.focus()
  },[headingRef])
  
  const scaleY = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001
  })

  // experiences.current.shift() // remove the first element, which is the welcome screen
  
  return (

      <div className="grid h-screen grid-rows-1 gap-0 bg-red-500 bg-opacity-0 ">

        <div className="fixed w-full h-full opacity-0 bg-solacedarkgray -z-10" />

        <motion.div style={{ scaleX: scaleY }} className="fixed top-0 z-20 w-full h-1 origin-left bg-solaceyellow"/>  

        <h1 className="fixed z-10 w-full h-[4.5rem] mb-10 px-3 py-2 text-center text-solacegray text-1xl bg-solacedarkgray font-satoshi wdn-text-hidden"
          ref={headingRef}
          aria-label="Solace Experiences" content="Solace Experiences"
        >
  
          <div className="flex flex-col items-center justify-center pt-2 ">
            <div className="absolute">
              <ReactSVG className="pt-10 text-white fill-current"
                src="https://solace-cdn.slv.tools/Hexagon.svg" 
              />
            </div>
            <div className="absolute">
              <ReactSVG className="pt-8 pl-2 text-white fill-current"
                src="https://solace-cdn.slv.tools/Hexagon.svg" 
              />
            </div>
          </div>
        </h1>

        <div className='fixed z-10 w-full pt-4 pr-3 text-right text-solacedarkgray '>
          <RWebShare
            data={{
              text: "Wanted to share this with you",
              url: "https://solace.slv.vic.gov.au",
              title: "Share Solace",
            }}
            sites={["facebook", "twitter", "linkedin", "whatsapp"]}
            onClick={() => console.log("shared successfully!")}
          >
            <button className="w-10 h-10 pb-3 text-2xl rounded-full bg-solaceshare" aria-label="Share" content="Share" >
              <ReactSVG className="pt-2 pl-2 stroke-current text-solacedarkgray"
                  src="https://solace-cdn.slv.tools/share.svg" 
                />
            </button>
          </RWebShare>
        </div> 

          {  process.env.NODE_ENV === 'development' 
          && <div className='fixed z-10 w-full pt-2 pl-2 text-xs text-left text-yellow-500 '>⚠️ v5.7 Developement</div> }

        <menu className="" role="menu" tabIndex={1}>
            <div className="h-4"></div>
        {
          experiences.current.map( (o,i) => (
            <CellView 
            title={o[0]}
            thumb={o[1].thumb}
            key={o[0]} 
            description={o[1].description}
            sensors={o[1].sensors}
            onClick={ () => {props.onClick && props.onClick(o[1].element);} }
            />
            ))
          }
        </menu>
      </div>
  )
}