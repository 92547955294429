import './App.css';
import { Suspense, useState, useMemo, useRef } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { createBrowserRouter, RouterProvider, Routes, Route, useLocation} from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import { StoreContextType, StoreContext } from './Hooks/useStore';
import { manifest } from './manifest';
import List from './List';
// import ReactGA from 'react-ga';
import { ReactSVG } from 'react-svg';
import TagManager from 'react-gtm-module';


//----------------------------------------------------------------------------
TagManager.initialize({
  gtmId: 'GTM-5FTVNW',
});

//----------------------------------------------------------------------------
const RenderRouter = () => {
  const [store, setStore] = useState<StoreContextType>({
    title: "Solace",
    id: 0,
    setTitle: (title: string) => {
      setStore({...store, title: title})
    }
  })

  const elementsList = useRef(new Array<JSX.Element>())

  useMemo(() => {
    manifest.scenes.forEach( (scene, k) => {
      elementsList.current.push(
        <Route
          path={k}  
          key={k}
          element={
            <Suspense fallback={<div className='text-white' ></div>} key={k+"_key"}>
              {scene.element}
            </Suspense>
          }
        />
      )
    })
  },[])

  //-----------------------------------------------------------------------
  const router = createBrowserRouter([
    { path: "*", Component: RenderRoutes }
  ])

  //-----------------------------------------------------------------------
  function RenderRoutes() {
    const location = useLocation()
    return (          
          <Routes location={location} key={location.pathname+"_route"}>
            {elementsList.current.map( (component, i) => {
              return component
            })}
            <Route path="/" element={ <List key="list_key"/> } key="root_key"/> 
          </Routes>
    )
  }

  //-----------------------------------------------------------------------
  return (
    <StoreContext.Provider value={store}>
      <main>
        <div className="fixed w-full h-full bg-solacedarkgray -z-10" />
        <RouterProvider router={router} />
      </main>
    </StoreContext.Provider>
  )
}

//----------------------------------------------------------------------------
//
//----------------------------------------------------------------------------
function App() {

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 612px)' })
  
  // ReactGA.initialize('G-C3M87FD4RB')
  // ReactGA.pageview(window.location.pathname + window.location.search)
  

  return (
    <div>
      <HelmetProvider>
        <Helmet>    

          <meta name="viewport" content="user-scalable=no,initial-scale=1.0,maximum-scale=1.0" />
          <meta http-equiv="ScreenOrientation" content="autoRotate:disabled" />
          {/* https://github.com/mariusjagminas/screen-orientation-react */}
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        </Helmet>
      </HelmetProvider>

      { !isTabletOrMobile &&
        <div className='w-screen h-screen font-sans text-center bg-solacedarkgray text-slate-200'>
          <div className='pt-16 mx-32 text-4xl text-center'>Solace is designed for mobile in portrait mode with headphones</div>

          <div className='grid content-center grid-flow-col grid-cols-3 pt-8'>
            <div className='flex justify-center col-start-2'>


            <ReactSVG 
            beforeInjection={(svg) => {
              svg.classList.add('svg-class-name')
              svg.setAttribute('style', 'width: 100px; height: 100px; ')
            }}
            className="text-white stroke-current fill-none wrapper-class-name"
            wrapper="span"
            evalScripts="always"
            src="https://solace-cdn.slv.tools/rotate_portrait2.svg" 
            />
            </div>
          </div>

          {/* <div className='pt-2 text-4xl'>Please us this QR Code to access on a mobile device</div> */}
          {/* <div  className='mt-10 align-middle'>
            <img className='w-1/4 mx-auto' src={"./assets/image/solace_v5.1_QRCode.png"} alt="Logo" />
          </div> */}
        </div>
      }
      
      { isTabletOrMobile &&
      <>
          {/* <ToneControl /> */}
          <RenderRouter />          
      </>
          // <ListRender />

      }

    </div>
  );
}

export default App;



/*


Notes:

We have a number of KPIs that we'll need to collect for our internal reporting, including:
Wa
Number of participants and app users (SLA program participants)  
Satisfaction (KR digital satisfaction)  
Postcode (KR regional Reach)  
Age (SLA Youth 0-25 metric)  
Library member – Y/N (general Library data collection)  
# Of First nations participants (KR Focus audience)   
We'll also need to include a link to our memberships and donations pages at some point in the app - either the end or beginning. 


//
gh issue create --title ""



# 30-3-2023

Paul (tech)
Arts Piano : ghost story

# 4-4-2023

what engine are you using for the build
  vercel / react-ts / aws storage of assets

what's the structure of the site
  mobile only / static site / single page vlog-roll navigation / compliant web accessibility guidelines

can it easily be broken into components/containers
  not atm / looking into next.js - code splitting / static site generation
what are the site requirements in terms of AV
  use of video, images, audio, microphone, motion 

REPO
https://github.com/StateLibraryVictoria/solace


Multi Repos GitHub
https://gist.github.com/rvl/c3f156e117e22a25f242



WEBSITE DEPLOYMENT

A quick summary of the hosted environment:
Github repo for the Solace site built with react

url: https://github.com/StateLibraryVictoria/solace
Steph has write access

magic branches prod/dev/stag auto-deploy on commit
Static site on Cloudflare Pages

prod: https://solace.slv.tools
stag: https://stag.solace-dvi.pages.dev
dev: https://dev.solace-dvi.pages.dev


CDN site using S3 + cloudflare CDN + cloudflare Image resizing
https://solace-cdn.slv.tools/[PATH TO FILE]
s3 bucket for uploading: s3://solace-cdn.slv.tools
Steph has AWS keys for uploading
steph-ohara-solace-cdn-rw_accessKeys

Access key ID	
AKIAUFIJTFDXEMQNFVVZ	
Secret access key
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


*/

// Icons
// https://react-icons.github.io/react-icons/search?q=ear



// GPS
// https://www.npmjs.com/package/react-geolocated




/*
Refactor

each experience has its own page and manifest
manifest interfaces : bunch of types / model interfaces
Expereinces : each experience is of a uber type made up of parts

  ExperienceA
    description
    sensors
    thumb
    assets

Use of unions



Loading Assets 



Code Splitting / Lazy loading / Suspence
https://refine.dev/blog/react-lazy-loading/ Route lazy loading!



Service Worker / PWA

Do we need this?
https://medium.com/swlh/converting-existing-react-app-to-pwa-3c7e4e773db3
https://dev.to/pettiboy/transforming-your-react-app-into-a-pwa-a-comprehensive-guide-59hk




Process / Web Workers

https://blog.logrocket.com/web-workers-react-typescript/
https://www.npmjs.com/package/@shopify/react-web-worker





Visual Audio Syncing

Using Tone.Draw.schedule :
https://github.com/Tonejs/Tone.js/wiki/Performance


Memory Analysis

https://web.dev/monitor-total-page-memory-usage/




Streaming Audio : 

Easiest is to play a video with no visuals


Animation :

https://www.react-spring.dev/docs/getting-started

Convert Audio to Movie with black :
ffmpeg -hwaccel videotoolbox -f lavfi -i color=c=black:s=1280x720:r=25/1 -i AUDIO_FILE.wav  -c:v h264_videotoolbox -b:v 50 -c:a aac -shortest MOVIE_FILE.mp4


Build :

https://parceljs.org/recipes/react/



Access :

https://www.freecodecamp.org/news/react-accessibility-tools-build-accessible-react-apps/
https://blog.saeloun.com/2022/01/27/tips-to-make-react-accessible/


https://github.com/GoogleChrome/accessibility-developer-tools
https://github.com/paypal/AATT
https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/
landmarks!
axe:
  https://github.com/dequelabs/axe-core-npm/tree/develop/packages/react

Flocusing :
https://developer.mozilla.org/en-US/docs/Learn/Tools_and_testing/Client-side_JavaScript_frameworks/React_accessibility


ARIA roles :
https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/button_role

HTML elements :
https://developer.mozilla.org/en-US/docs/Web/HTML/Element/main



UI :
  https://www.radix-ui.com/docs/primitives/components/slider
  https://ariakit.org/examples/menu-bar
  https://headlessui.com

  https://react-spectrum.adobe.com/react-aria/

  https://mui.com/base/getting-started/overview/

Design :

app icon (favicon)
colours
fonts




JS TO TS
https://www.turing.com/kb/migrate-javascript-to-typescript



npm version

increment minor : npm version minor

https://gist.github.com/jonlabelle/706b28d50ba75bf81d40782aa3c84b3e



State

zustand (machine)
jotai (global)
cache data : users session / local storage 
  what users have expereienced 
  where were they last etc.
https://github.com/jsheaven/simply-persist

  

Performance 

Break up assets / manifest code splitting
https://discoverthreejs.com/book/introduction/get-threejs/
https://github.com/imteekay/web-performance-research




openai 
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


OpenCV stuff
https://www.npmjs.com/package/@techstark/opencv-js



Video:

notes on rendering :
      https://stackoverflow.com/questions/70173320/three-js-video-texture-color-issue
      https://discourse.threejs.org/t/how-can-i-enable-emissions-from-video-texture/43019

      converting video : 
      https://gist.github.com/Vestride/278e13915894821e1d6f
      https://gist.github.com/jaydenseric/220c785d6289bcfd7366
      

       nice conversion #1
      ffmpeg -i IMG_2053.mov -c:v libx264 -pix_fmt yuv420p -profile:v baseline -level 3.0 -crf 22 -preset veryslow -vf scale=1280:-2 -an -movflags +faststart -threads 0 IMG_2053_comp

      ffmpeg -i XXX.mp4  -c:v libx264 -pix_fmt yuv420p -profile:v baseline -level 3.0 -crf 22 -preset veryslow -vf scale=1280:-2 -an -movflags +faststart -threads 0 XXX.mp4

      NOTE : -an stops copying audio

      ffmpeg -i xxxx.mov  -c:v libx264 -pix_fmt yuv420p -profile:v baseline -level 3.0   -crf 22 -preset veryslow -vf scale=1280:-2 -c:a aac -b:a 128k -movflags +faststart -threads 0 xxxx.mp4




Convert Audio to Movie with black :
ffmpeg -hwaccel videotoolbox -f lavfi -i color=c=black:s=1280x720:r=25/1 -i AUDIO_FILE.wav  -c:v h264_videotoolbox -b:v 50 -c:a aac -shortest MOVIE_FILE.mp4


mov - jpg
ffmpeg -i BehindtheCurtain_Video_BunnyBreastfeed_B_1.mp4 -r 1/1 $filename%03d.jpeg


GIF / ffmpeg
ffmpeg -pattern_type glob -i '*.jpg' chess_1.gif

ffmpeg -pattern_type glob -i '*.jpg' -s 400x600 chess_3.gif

possible use of grabbing frames from gif and drawing to canvas
https://www.npmjs.com/package/gifuct-js
https://github.com/matt-way/gifuct-js

Convert Audio to Movie with black :
ffmpeg -hwaccel videotoolbox -f lavfi -i color=c=black:s=1280x720:r=25/1 -i AUDIO_FILE.wav  -c:v h264_videotoolbox -b:v 50 -c:a aac -shortest MOVIE_FILE.mp4

Batch processing images
for i in *.jpg; do ffmpeg -i "$i" -vf scale=600:900 "${i%.*}_600x900.jpg"; done

behind the curtain
ffmpeg -ss 4 -t 77   -i BehindtheCurtain_Video_BunnyBreastfeed_B_1.mp4 -vf "fps=1,settb=1/2,scale=320:-1:flags=lanczos,split[s0][s1];[s0]palettegen[p];[s1][p]paletteuse" -r 1  -loop 0 output.gif

for i in *.wav; do ffmpeg -hwaccel videotoolbox -f lavfi -i color=c=black:s=1280x720:r=25/1 -i "$i"  -c:v h264_videotoolbox -b:v 50 -c:a aac -shortest "${i%.*}.mp4"; done



Router to data api

https://reactrouter.com/en/main/routers/create-browser-router

then 

scroll restoration
https://reactrouter.com/en/main/components/scroll-restoration


OSC / websockets

https://www.npmjs.com/package/node-osc
https://socket.io/how-to/use-with-react


      */




/*

05/06


+ interactive music ideas
+ motion music experience
+ move site over to slv.site
+ move assets to slv.aws
+ finish off current score
+ get scroll values for manipulating wayfinding graphics

*/


/*
Batch processing images
for i in *.jpg; do ffmpeg -i "$i" -vf scale=600:900 "${i%.*}_600x900.jpg"; done



*/

//https://mui.com/material-ui/material-icons/?query=tool
/*

<div class="bg-black h-screen p-12">

<div class="relative h-fit w-fit" style="width:36px; height:36px;">
  <div class="absolute top-0 left-0">
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" class="fill-white">
    <path d="M25.0747 0.91241V0H10.5423L0 10.5424V25.4529L10.5423 36H25.4576L36 25.4529V10.5424L25.4576 0H25.0747V0.91241L24.4271 1.56008L34.1704 11.2988V24.6965L24.6965 34.1657H11.3035L1.82954 24.6965V11.2988L11.3035 1.82955H25.0747V0.91241Z"/>
    </svg>
  </div>
  <div class="absolute top-0 left-0" style="transform: translate(5px, -5px);">
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" class="fill-white">
    <path d="M25.0747 0.91241V0H10.5423L0 10.5424V25.4529L10.5423 36H25.4576L36 25.4529V10.5424L25.4576 0H25.0747V0.91241L24.4271 1.56008L34.1704 11.2988V24.6965L24.6965 34.1657H11.3035L1.82954 24.6965V11.2988L11.3035 1.82955H25.0747V0.91241Z"/>
    </svg>
  </div>
</div>

</div>


<div class="bg-black h-screen p-12">

  <div class="mb-10">
    <div class="relative h-fit w-fit animate-spin-fade" style="width:36px; height:36px;">
      <div class="absolute top-0 left-0">
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" class="fill-white">
        <path d="M25.0747 0.91241V0H10.5423L0 10.5424V25.4529L10.5423 36H25.4576L36 25.4529V10.5424L25.4576 0H25.0747V0.91241L24.4271 1.56008L34.1704 11.2988V24.6965L24.6965 34.1657H11.3035L1.82954 24.6965V11.2988L11.3035 1.82955H25.0747V0.91241Z"/>
        </svg>
      </div>
      <div class="absolute top-0 left-0">
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" class="fill-white">
        <path d="M25.0747 0.91241V0H10.5423L0 10.5424V25.4529L10.5423 36H25.4576L36 25.4529V10.5424L25.4576 0H25.0747V0.91241L24.4271 1.56008L34.1704 11.2988V24.6965L24.6965 34.1657H11.3035L1.82954 24.6965V11.2988L11.3035 1.82955H25.0747V0.91241Z"/>
        </svg>
      </div>
    </div>
  </div>

    <div class="mb-10">
    <div class="relative h-fit w-fit" style="width:36px; height:36px;">
      <div class="absolute top-0 left-0">
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" class="fill-white">
        <path d="M25.0747 0.91241V0H10.5423L0 10.5424V25.4529L10.5423 36H25.4576L36 25.4529V10.5424L25.4576 0H25.0747V0.91241L24.4271 1.56008L34.1704 11.2988V24.6965L24.6965 34.1657H11.3035L1.82954 24.6965V11.2988L11.3035 1.82955H25.0747V0.91241Z"/>
        </svg>
      </div>
      <div class="absolute top-0 left-0 offset">
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" class="fill-white">
        <path d="M25.0747 0.91241V0H10.5423L0 10.5424V25.4529L10.5423 36H25.4576L36 25.4529V10.5424L25.4576 0H25.0747V0.91241L24.4271 1.56008L34.1704 11.2988V24.6965L24.6965 34.1657H11.3035L1.82954 24.6965V11.2988L11.3035 1.82955H25.0747V0.91241Z"/>
        </svg>
      </div>
    </div>
  </div>

</div>


*/



/*
Update node

npm cache clean -f 
  npm install -g n




    "quaternion": "^1.4.1",

    rm -rf node_modules
    npm install
    npm install @xxxxxx --legacy-peer-deps
  https://solaceslv.s3.ap-south-1.amazonaws.com/Hexagon.svg 
  https://solaceslv.s3.ap-south-1.amazonaws.com/Move_icon.svg 
    
*/