import { lazy } from 'react';
import { Experience, Manifest } from "./ModelTypes";

// code splitting

// const List = lazy(() => import('./List'));

const Welcome = lazy(() => import('./Experiences/Welcome'));
const Instructions = lazy(() => import('./Experiences/Instructions'));
const Conservatory = lazy(() => import('./Experiences/Conservatory'));
const WhaleSong = lazy(() => import('./Experiences/WhaleSong'));
const ThroughTheWindow = lazy(() => import('./Experiences/ThroughTheWindow'));
const WhiteRabbit = lazy(() => import('./Experiences/WhiteRabbit'));
const LostShadows = lazy(() => import('./Experiences/LostShadows'));
const FlowFidget = lazy(() => import('./Experiences/FlowFidget'));
const PiecesPromenading = lazy(() => import('./Experiences/PiecesPromenading'));
const TemporalVisions = lazy(() => import('./Experiences/TemporalVisions'));
const MusicalPieces = lazy(() => import('./Experiences/MusicalPieces'));
const Credits = lazy(() => import('./Experiences/Credits'));

const PiecesAscending = lazy(() => import('./Experiences/PiecesAscending'));
const HoppingThrough = lazy(() => import('./Experiences/HoppingThrough'));
// const BehindTheCurtain = lazy(() => import('./Experiences/BehindTheCurtain'));
const FossilFidget = lazy(() => import('./Experiences/FossilFidget'));
const ShadesOfTrees = lazy(() => import('./Experiences/ShadesOfTrees'));

// const Survey = lazy(() => import('./Experiences/Survey'));

// const HoldingHands = lazy(() => import('./Experiences/HoldingHands'));
// const IconPage = lazy(() => import('./Experiences/IconPage'));
// const P5Tester = lazy(() => import('./Experiences/P5Tester'));
// const XMotionSound = lazy(() => import('./Experiences/XMotionSound'));
// const YMotionSound = lazy(() => import('./Experiences/YMotionSound'));

// const XAxisTester = lazy(() => import('./Experiences/XAxisTester'));
// const YAxisTester = lazy(() => import('./Experiences/YAxisTester'));

// const Camera = lazy(() => import0('./Experiences/Camera'));
// const Touch = lazy(() => import('./Experiences/Touch'));
// const Microphone = lazy(() => import('./Experiences/Microphone'));
// const ToneTester = lazy(() => import('./Experiences/ToneTester'));


export const manifest: Manifest = {
  scenes: new Map<string, Experience>([


    ["Welcome to Country", {
      description: "Wurundjeri Woi Wurrung Elder, Aunty Diane Kerr OAM, speaks to her ancestors and Country where the State Library of Victoria stands. ",
      sensors: {visual:true, audio:true, touch:false, smell:false, move:false, tool:false},
      thumb: "welcome/domeBlue2.jpg",
      element: <Welcome title={"Welcome to Country"} key={"welcome_key"}/>
    }],   

    ["Start Here", {
      description: "This short introduction will help you get started with Solace.",
      sensors: {visual:true, audio:true, touch:false, smell:false, move:false, tool:false},
      thumb: "instructions/StartHere_Thumbnail_WenJuennDomeWithPhone_2.png",
      element: <Instructions title={"Start Here"} key={"instructions_key"}/>
    }],   

    ["White Rabbit White Rabbit White Rabbit", {
      description: "Under the dome of the La Trobe Reading Room, find the White Rabbit. Where is it going? What does it want? ",
      sensors: {visual:true, audio:true, touch:false, smell:false, move:true, tool:false},
      thumb: "whiterabbit/WhiteRabbitWhiteRabbit_Thumbnail_Dome_1.jpg",
      element: <WhiteRabbit title={"White Rabbit, White Rabbit, White Rabbit"} />,
    }],

    ["Pieces Ascend", {
      description: "Interact with animated chess pieces, moving them up and down the stairs of the West link staircase.",
      sensors: {visual:true, audio:true, touch:true, smell:true, move:true, tool:false},
      thumb: "piecesascend/PiecesAscending_thumbnail_westlinkstairs_1.jpg",
      element: <PiecesAscending title={"Pieces Ascend"} key={"piecesascend_key"}/>
    }],    

    ["Temporal Visions", {
      description: "At the base of the West link staircase, a human body expands and contracts,"+
      " dancing through time. A labyrinth of stairways leads in all directions.",
      sensors: {visual:true, audio:true, touch:false, smell:false, move:true, tool:false},
      thumb: "temporalvisions/TemporalVisions_Thumbnail_WestlinkStairs_1.jpg",
      element: <TemporalVisions title={"Temporal Visions"}/>,
    }],    

    ["Whale Song", {
      description: "Rain is falling on the roof of the Redmond Barry Reading Room. The sun rises. A whale begins to sing.",
      sensors: {visual:true, audio:true, touch:false, smell:false, move:true, tool:false},
      thumb: "whalesong/WhaleSong_Thumbnail_RedmondBarry_2.jpg",
      element: <WhaleSong title={"Whale Song"} />,
    }],    


    ["Fossil Fidget", {
      description: "Find the tiny fossils within the marble of the West link staircase. Let milliseconds become millennia.",
      sensors: {visual:true, audio:true, touch:true, smell:true, move:false, tool:false},
      thumb: "fossilfidget/FossilFidget_Still_WestlinkStairs_1.jpg",
      element: <FossilFidget title={"Fossil Fidget"} key={"fossilkey"}/>
    }],    

    ["Lost Shadows", {
      description: "Enter the sunlit shadows of Create Quarter, where light refracts and shadows meet. ",
      sensors: {visual:true, audio:true, touch:false, smell:false, move:true, tool:false},
      thumb: "lostshadows/LostShadows_thumbnail_shadowsonly_1.png",
      element: <LostShadows title={"Lost Shadows"}/>,
    }],


    ["Pieces Promenade", {
      description: "At the base of a column in the Queen's Hall, chess pieces circle. Tap to play with them in a meditative loop.",
      sensors: {visual:true, audio:true, touch:true, smell:false, move:false, tool:false},
      thumb: "piecespromenading/PiecesPromenade_Thumbnail_QueensHall_1.jpg",
      element: <PiecesPromenading title={"Pieces Promenade"}/>
    }],    

    ["Through the window", {
      description: "A window as a portal, a barrier separating outside and in. Watch the trees sway through the window in Queen's Hall.",
      sensors: {visual:true, audio:true, touch:false, smell:false, move:true, tool:false},
      thumb: "throughtthewindow/ThroughTheWindow_Thumbnail_QueensHall_1.jpg",
      element: <ThroughTheWindow title={"Through the Window"} />,
    }],  

    ["Shades of Trees", {
      description: "In Create Corner, light hits and ideas refract. Is there a shadow of an idea growing on the walls?",
      sensors: {visual:true, audio:true, touch:false, smell:false, move:true, tool:false},
      thumb: "shadesoftrees/shades.jpg",
      element: <ShadesOfTrees title={"Shades of Trees"} key={"shadestreeskey"}/>
    }],    

    ["Musical Pieces", {
      description: "These Information Centre lockers house musical notes, and sometimes, giant chess pieces.",
      sensors: {visual:true, audio:true, touch:true, smell:false, move:false, tool:false},
      thumb: "musicalpieces/UnlockThePieces_Thumbnail_InfoCentre_2.jpg",
      element: <MusicalPieces title={"Musical Pieces"} key={"unclock_key"}/>
    }],    

    ["Conservatory", {
      description: "A shadow flickers and dances behind glass. What was once an outdoor courtyard has become Create Quarter.",
      sensors: {visual:true, audio:true, touch:false, smell:true, move:true, tool:false},
      thumb: "conservatory/Conservatory_Thumbnail_CreateQuarter_1.png",
      element: <Conservatory title={"Conservatory"}/>,
    }],    

    // ["Behind the Curtain", {
    //   description: "In a quiet corner of Children's Quarter, something waits behind a curtain. Is it is human or animal or something in between?",
    //   sensors: {visual:true, audio:true, touch:true, smell:true, move:false, tool:false},
    //   thumb: "behindthecurtain/BehindtheCurtain_Thumbnail_ChildrensQuarter_1.jpg",
    //   element: <BehindTheCurtain title={"Behind the Curtain"} key={"behind_key"}/>
    // }],    

    ["Hopping Through Time", {
      description: "The White Rabbit hops in between the columns of the Queen's Hall. Follow it into an invisible, vanishing point until time and space dissolve.",
      sensors: {visual:true, audio:true, touch:true, smell:true, move:true, tool:false},
      thumb: "hoppingthrough/HoppingThroughTime_thumbnail_QueensHall_1.png",
      element: <HoppingThrough title={"Hopping Through Time"} key={"hoppingthrough_key"}/>
    }],    

    ["Flow Fidget", {
      description: "Follow the air flowing through the Information Centre. Allow yourself to fidget, rustle, unsettle, breathe.",
      sensors: {visual:true, audio:true, touch:true, smell:false, move:false, tool:false},
      thumb: "flowfidget/FlowFidget_Thumbnail_WithInfoCentre_2.jpg",
      element: <FlowFidget title={"Flow Fidget"} key={"flowfidget_key"}/>
    }],    






    ["Credits", {
      description: "",
      sensors: {visual:true, audio:false, touch:false, smell:false, move:false, tool:false},
      thumb: "info_centre_roof_lights.png",
      element: <Credits title={"Credits"}/>,
    }],    


    // ["Survey", {
    //   description: "Thanks for testing Solace. Please take a moment to complete an online survey about your experience.",
    //   sensors: {visual:true, audio:true, touch:true, smell:true, move:true, tool:true},
    //   thumb: "question.png",
    //   element: <Survey title={"Survey"} key={"survey_key"}/>
    // }],   








    // ["About Icons", {
    //   description: "Show all ths icons.",
    //   sensors: {visual:true, audio:true, touch:true, smell:true, move:true, tool:false},
    //   thumb: "tools.png",
    //   element: <IconPage title={"About the Icons"} key={"icons_key"}/>
    // }],    



    // ["XAxis Tester", {
    //   description: "Tester for motion sensor data",
    //   sensors: {visual:false, audio:false, touch:false, smell:false, move:false, tool:true},
    //   thumb: "Tools.png",
    //   element: <XAxisTester title={"XAxisTester"} />,
    // }],    


    // ["YAxis Tester", {
    //   description: "Tester for motion sensor data",
    //   sensors: {visual:false, audio:false, touch:false, smell:false, move:false, tool:true},
    //   thumb: "Tools.png",
    //   element: <YAxisTester title={"YAxisTester"} />,
    // }],  


    // ["Y Motion Sound", {
    //   description: "Tester for x motion sensor data",
    //   sensors: {visual:false, audio:false, touch:false, smell:false, move:false, tool:true},
    //   thumb: "Tools.png",
    //   element: <YMotionSound title={"YMotionSound"} />,
    // }],    


    // ["X Motion Sound", {
    //   description: "Tester for x motion sensor data",
    //   sensors: {visual:false, audio:false, touch:false, smell:false, move:false, tool:true},
    //   thumb: "Tools.png",
    //   element: <XMotionSound title={"XMotionSound"} />,
    // }],    



   

    // ["P5 Tester", {
    //   description: "Wave you hands around in front of your device to make sounds",
    //   sensors: {visual:true, audio:true, touch:true, smell:true, move:true, tool:true},
    //   thumb: "Tools.png",
    //   element: <P5Tester title={"P5Tester"}/>
    // }],    

 




   





    

    // ["Testing Tone", {
    //   description: "Testing Tone",
    //   sensors: {visual:true, audio:true, touch:true, smell:true, move:true, tool:true},
    //   thumb: "Tools.png",
    //   element: <ToneTester />
    // }],    



    // ["Camera", {
    //   description: "Tester for camera input",
    //   sensors: {visual:false, audio:false, touch:false, smell:false, move:false, tool:true},
    //   thumb: "Tools.png",
    //   element: <Camera />,
    // }],    

    // ["Touch", {
    //   description: "Tester for touch sensor data",
    //   sensors: {visual:false, audio:false, touch:true, smell:false, move:false, tool:true},
    //   thumb: "Tools.png", 
    //   element: <Touch />,
    // }],

    // ["Microphone", {
    //   description: "Microphone input",
    //   sensors: {visual:false, audio:false, touch:false, smell:false, move:false, tool:true},
    //   thumb: "Tools.png",
    //   element: <Microphone />,
    // }], 

  ])
}

